<template>
  <div class="pa-3">
    <v-dialog v-model="showEditPlan" width="500">
      <v-card>
        <v-card-title class="font-weight-bold accent--text">
          {{ openADRPlanTypes[target.planType - 1] }}
        </v-card-title>

        <v-card-text>
          <div class="text-right">
            <GroupAppliedHint />
          </div>
          <v-autocomplete
            v-model="target.group"
            class="mt-3 mx-5"
            label="執行群組"
            outlined
            dense
            hide-details
            background-color="white"
            :items="groupsForTable"
            multiple
          >
            <template v-slot:item="{ item }">
              <div class="d-flex" style="width: 100%">
                <div>
                  {{ item.value.name }}
                </div>
                <v-spacer></v-spacer>
                <GroupApply :group="item.value" />
              </div>
            </template>
          </v-autocomplete>
          <v-row v-if="target.planType === 1" class="mt-3 mx-2">
            <v-col>
              <v-select
                v-model="target.offloadMode"
                :items="eventOptions"
                placeholder="選擇執行動作"
                hide-details
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col v-if="target.offloadMode === 3">
              <v-select
                placeholder="請選擇溫度"
                v-model="target.temperature"
                :items="temperatureOption"
                item-value="acResponseValue"
                hide-details
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="showEditPlan = false"> 取消 </v-btn>
          <v-btn
            :disabled="!target.group"
            color="primary"
            text
            @click="setOpenADRGroup"
          >
            確認
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="mb-10">
      <div class="text--secondary">ADR註冊狀態</div>
      <div class="ma-3">
        <status-card :school-id="tagId" />
      </div>
    </div>
    <div>
      <div class="text--secondary">ADR方案</div>
      <div class="red--text">實際執行內容將依據您與台電的合約方案</div>
      <v-card class="pa-2 mt-2">
        <v-data-table :headers="headers" :items="ADRPlans" hide-default-footer>
          <template v-slot:[`item.planType`]="{ value }">
            {{ openADRPlanTypes[value - 1] }}
          </template>
          <template v-slot:[`item.group`]="{ item }">
            <v-chip class="mr-1" v-for="group in item.group">{{
              group.name
            }}</v-chip>
            <span
              class="ml-2 primary--text"
              v-if="item.planType === 1 && item.group.length"
              >(
              {{ eventText(item) }}
              )</span
            >
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <template v-if="item.group">
              <v-btn small icon @click="showSettings(item)"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
              <v-btn
                small
                depressed
                class="ml-2"
                icon
                @click="deleteOpenADRGroup(item.planType)"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <v-btn
              v-else
              color="primary"
              small
              depressed
              @click="
                target.planType = item.planType
                target.group = item.group
                showEditPlan = true
              "
            >
              新增群組
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div class="mt-10">
      <div class="text--secondary">不參加日期設定</div>
      <div class="ml-3">
        <v-row>
          <v-col cols="12" sm="8" lg="3" xl="3">
            <v-btn
              color="primary"
              href="https://adr-school.taipower.com.tw/Login.aspx"
            >
              前往設定
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- <div class="ml-3">
        <v-row>
          <v-col cols="12" sm="8" lg="3" xl="3">
            <InputDateRangePicker v-model="selectData" range />
          </v-col>
        </v-row>
      </div>
      <div class="ml-6">
        <v-row>
          <v-col
            v-for="(optout, key) in eventOptout"
            cols="12"
            sm="6"
            lg="3"
            :key="key"
          >
            <v-card class="ems-opacity-75-bg">
              <v-toolbar dense elevation="0" color="transparent">
                <div>{{ optout.optoutDtstart }} ~ {{ optout.optoutDtend }}</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="deleteEventOptout(optout.id)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-toolbar>
            </v-card>
          </v-col>
        </v-row>
      </div> -->
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MeterOffLoading from '@/api/ems/MeterOffLoading'
import OpenADR from '@/api/ems/OpenADR'
import InputDateRangePicker from './InputDateRangePicker'
import statusCard from './statusCard'
import Swal from 'sweetalert2'
import GroupAppliedHint from '@/views/school/groupApplied/Hint'
import GroupApply from '@/views/school/groupApplied/Apply'

export default {
  name: undefined,
  mixins: [],
  components: {
    InputDateRangePicker,
    statusCard,
    GroupAppliedHint,
    GroupApply
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      overlay: false,
      openADRPlanTypes: ['卸載約定型', '空調循環型', '溫度控制型'],
      openADRPlans: [],
      headers: [
        {
          text: '方案名稱',
          value: 'planType',
          sortable: false
        },
        {
          text: '執行群組',
          value: 'group',
          sortable: false
        },
        {
          value: 'action',
          width: 150,
          sortable: false
        }
      ],
      showEditPlan: false,
      target: {
        planType: '',
        group: {},
        offloadMode: 1,
        temperature: null
      },
      eventOptions: [
        {
          text: '溫度調整',
          value: 3
        },
        {
          text: '送風模式',
          value: 4
        },
        {
          text: '關閉冷氣',
          value: 1
        }
      ]
      // unloadingList: [],
      // headers: [
      //   {
      //     text: '執行順序',
      //     value: 'priority'
      //   },
      //   {
      //     text: '方案名稱',
      //     value: 'name'
      //   },
      //   {
      //     text: '執行對象',
      //     value: 'groupId'
      //   }
      // ],
      // selectData: [],
      // eventOptout: []
    }
  },
  computed: {
    ...mapGetters({
      groups: 'groups/groups'
    }),
    temperatureOption() {
      return this.$store.getters['ac/temperatureOption'].map((item) => {
        item.acResponseValue = Number(item.acResponseValue)
        return item
      })
    },
    ADRPlans() {
      return this.openADRPlanTypes.map((plan, index) => {
        let samePlanTypePlans = this.openADRPlans.find(
          (p) => p.planType === index + 1
        )
        return {
          planType: index + 1,
          group: this.groups?.filter(({ id }) =>
            samePlanTypePlans?.groupId.some((groupId) => groupId === id)
          ),
          offloadMode: samePlanTypePlans?.offloadMode,
          temperature: samePlanTypePlans?.temperature
        }
      })
    },
    groupsForTable() {
      // ADR 設定方案群組僅能設定新設冷氣群組 ( group.type = 'AIR_CON' )
      return (
        this.groups
          ?.filter(({ type }) => type === 'AIR_CON')
          .map((item) => ({ text: item.name, value: item })) || []
      )
    }
  },
  // watch: {
  //   selectData(newVal, oleVal) {
  //     if (newVal && newVal.length === 2) {
  //       this.createEventOptout()
  //     }
  //   }
  // },
  mounted: function () {
    // this.getUnloadingList()
    // this.getEventOptout()
    this.getGroups(this.tagId)
    this.getOpenADRGroups()
  },
  destroyed() {},
  methods: {
    ...mapActions({
      getGroups: 'groups/getGroups',
      showMsg: 'snackbar/show'
    }),
    async getOpenADRGroups() {
      this.overlay = true
      try {
        const { data } = await OpenADR.getOpenADRGroups(this.tagId)
        console.log(data)
        this.openADRPlans = data
      } catch (error) {
        console.log(error.response)
      } finally {
        this.overlay = false
      }
    },
    openADRPlanGroupId(index) {
      let planType = index + 1
      return this.openADRPlans.find((plan) => plan.planType === planType)
        ?.groupId
    },
    async setOpenADRGroup() {
      this.showEditPlan = false
      try {
        await OpenADR.setOpenADRGroup(
          this.$route.params.tagId,
          this.target.planType,
          {
            groupId: this.target.group.map(({ id }) => id),
            ...(this.target.planType === 1 && {
              offloadMode: this.target.offloadMode
            }),
            ...(this.target.planType === 1 &&
              this.target.offloadMode === 3 && {
                temperature: this.target.temperature
              })
          }
        )
      } catch (error) {
        console.error(error)
        this.showMsg({
          message: `設定群組失敗`,
          color: 'error',
          closable: true,
          time: 4000
        })
      }
      this.getOpenADRGroups()
    },
    deleteOpenADRGroup(planType) {
      Swal.fire({
        title: `確定要刪除${this.openADRPlanTypes[planType - 1]}群組?`,
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '確定',
        confirmButtonColor: '#3085d6'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await OpenADR.deleteOpenADRGroup(this.$route.params.tagId, planType)
          } catch (error) {
            const msg =
              error.response.data.errorMessage || '發生錯誤，請稍後再試'
            this.showMsg({
              message: `${error.response.status + ' ' || ''} ${msg}`,
              color: 'error',
              closable: true,
              time: 4000
            })
          } finally {
            this.getOpenADRGroups()
          }
        }
      })
    },
    showSettings(item) {
      this.target.planType = item.planType
      this.target.group = item.group
      this.showEditPlan = true
      if (item.planType === 1) {
        this.target.offloadMode = item.offloadMode
        if (item.offloadMode === 3) this.target.temperature = item.temperature
      }
    },
    eventText(item) {
      if (item.offloadMode) {
        let displayText = this.eventOptions.find(
          ({ value }) => value === item.offloadMode
        )?.text
        if (item.offloadMode === 3) {
          const temperature = this.temperatureOption.find(
            (element) => element.acResponseValue === item.temperature
          )
          return displayText + '為' + temperature.text
        }
        return displayText
      }
    }
    // getUnloadingList() {
    //   this.overlay = true
    //   MeterOffLoading.getPlan(this.tagId)
    //     .then((response) => {
    //       this.unloadingList = response.data.sort((item) => {
    //         return item.priority
    //       })
    //     })
    //     .catch((error) => {
    //       console.log(error.response)
    //     })
    //     .finally(() => {
    //       this.overlay = false
    //     })
    // }
    // getEventOptout() {
    //   this.overlay = true
    //   OpenADR.eventOptout(this.tagId)
    //     .then((response) => {
    //       this.eventOptout = response.data
    //     })
    //     .catch((error) => {
    //       console.log(error.response)
    //     })
    //     .finally(() => {
    //       this.overlay = false
    //     })
    // },
    // createEventOptout() {
    //   this.overlay = true
    //   OpenADR.createEventOptout({
    //     schoolId: this.tagId,
    //     optoutDt: {
    //       optoutDtstart: this.selectData[0],
    //       optoutDtend: this.selectData[1]
    //     }
    //   })
    //     .then((response) => {
    //       this.selectData = []
    //       this.getEventOptout()
    //     })
    //     .catch((error) => {
    //       console.log(error.response)
    //       this.showMsg({
    //         message: error.response.data.errorMessage || '新增失敗',
    //         closable: true,
    //         time: 2000
    //       })
    //     })
    //     .finally(() => {
    //       this.overlay = false
    //     })
    // },
    // deleteEventOptout(optoutDtId) {
    //   this.overlay = true
    //   OpenADR.deleteEventOptout(optoutDtId)
    //     .then((response) => {
    //       this.getEventOptout()
    //     })
    //     .catch((error) => {
    //       console.log(error.response)
    //       this.showMsg({
    //         message: error.response.data.errorMessage || '刪除失敗',
    //         closable: true,
    //         time: 2000
    //       })
    //     })
    //     .finally(() => {
    //       this.overlay = false
    //     })
    // }
  }
}
</script>

<style></style>
